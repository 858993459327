@import url('https://fonts.googleapis.com/css2?family=Anton&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

@import url(./_scss/normalize.css);
// @import url(./_scss/skeleton.css);
@import url(./_scss/simplegrid.css);

@import url(./_scss/_functions.scss);
@import url(./_scss/_mixins.scss);
@import url(./_scss/_variables.scss);
@import url(./_scss/_typography.scss);

// Theme
@import url(./_scss/_theme.scss);

// Components
@import './_components/1-atoms/button/Button.scss';
@import './_components/1-atoms/Blobby/Blobby.scss';

// Molecules
@import './_components/2-molecules/grid-tile/GridTile.scss';

// Organisms
@import './_components/3-organisms/Header/Header.scss';
@import './_components/3-organisms/Navbar/Navbar.scss';
@import './_components/3-organisms/Banner/Banner.scss';
@import './_components/3-organisms/Modal/Modal.scss';

* {
    box-sizing: border-box;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    background-color: var(--bg);
}

body {
    margin: 0;
    // @include reset(body);
    font-family: var(--font-family-body);
    background-color: var(--bg);
    color: var(--font-color-paragraph);
}


main {
    padding-top: 48px;
}

section {
    padding-top: 80px;
    margin-top: -80px;
    background-color: var(--bg);

    & ~ section {
        background-color: var(--bg-alt);
        padding-bottom: 80px;
    }
}

img {
    max-width: 100%;
}

@keyframes backdrop_roll { from { right: 0px; } to { right: 320px; } }
.line-animations {
    width: 68px;
    height: 17px;
    overflow: hidden;
    display: inline-block;
}

.line-bg {
    position: relative;
    background: url('data:image/svg+xml,<svg width="82" height="17" viewBox="0 0 82 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.27735 0.583975L1 0.16795L0.4453 1L0.72265 1.41603L1.27735 0.583975ZM0.72265 1.41603L10.7226 16.416L11.2773 15.584L1.27735 0.583975L0.72265 1.41603Z" fill="%232A2E34"/><path d="M10.7226 15.584L10.4453 16L11 16.8321L11.2774 16.416L10.7226 15.584ZM11.2774 16.416L21.2773 1.41603L20.7226 0.583976L10.7226 15.584L11.2774 16.416Z" fill="%232A2E34"/><path d="M21.2774 0.583975L21 0.16795L20.4453 1L20.7226 1.41603L21.2774 0.583975ZM20.7226 1.41603L30.7226 16.416L31.2773 15.584L21.2774 0.583975L20.7226 1.41603Z" fill="%232A2E34"/><path d="M30.7226 15.584L30.4453 16L31 16.8321L31.2774 16.416L30.7226 15.584ZM31.2774 16.416L41.2773 1.41603L40.7226 0.583976L30.7226 15.584L31.2774 16.416Z" fill="%232A2E34"/><path d="M41.2774 0.583975L41 0.16795L40.4453 1L40.7226 1.41603L41.2774 0.583975ZM40.7226 1.41603L50.7226 16.416L51.2773 15.584L41.2774 0.583975L40.7226 1.41603Z" fill="%232A2E34"/><path d="M50.7226 15.584L50.4453 16L51 16.8321L51.2774 16.416L50.7226 15.584ZM51.2774 16.416L61.2773 1.41603L60.7226 0.583976L50.7226 15.584L51.2774 16.416Z" fill="%232A2E34"/><path d="M61.2774 0.583975L61 0.16795L60.4453 1L60.7226 1.41603L61.2774 0.583975ZM60.7226 1.41603L70.7226 16.416L71.2773 15.584L61.2774 0.583975L60.7226 1.41603Z" fill="%232A2E34"/><path d="M70.7226 15.584L70.4453 16L71 16.8321L71.2774 16.416L70.7226 15.584ZM71.2774 16.416L81.2773 1.41603L80.7226 0.583976L70.7226 15.584L71.2774 16.416Z" fill="%232A2E34"/></svg>') repeat-x;
    width: 1320px;
    height: 17px;
    animation: backdrop_roll linear 12s infinite;

    &--inverted {
        filter: invert(1);
    }
}




.contactIcon {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: var(--grey-600);
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        // max-width: 50%;
    }
}

.pill {
    // height: 10px;
    padding: 2px 8px;
    font-size: 10px;
    border-radius: 8px;
    background-color: var(--gold);
    margin-right: 4px;
    font-weight: bold;
    color: var(--grey-600);
    display: inline-block;
}