.btn {
    background-color: var(--grey-600);
    padding: 2px 32px;
    border-radius: 0;
    height: 48px;
    font-weight: bold;
    text-decoration: none;
    // border-top: 1px solid var(--gold);
    // border-right: 1px solid var(--gold);
    position: relative;
    overflow: visible;
    display: inline-flex;
    align-items: center;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 3px);
        height: calc(100% - 2px);
        transition: all 0.15s;
        border-bottom: 1px solid var(--grey-600);
        border-left: 1px solid var(--grey-600);
        border-top: 1px solid var(--grey-400);
        border-right: 1px solid var(--grey-400);
        background-color: var(--gold);
        z-index: -1;
    }

    &--primary {
        background-color: var(--grey-600);
        color: var(--gold);
        border-color: var(--gold);
    }

    &--tiny {
        height: 30px;
        font-size: 12px;
        padding: 2px 16px;
    }

    &:hover,
    :focus,
    :focus-within {
        background-color: rgb(50, 55, 63);
        // filter: brightness(0.91);

        &::after {
            transform: translate(5px, 5px);
        }
    }
}





.icon-button {
    // width: 30px;
    height: 30px;
    display: inline-flex;
    background-color: var(--grey-600);
    color: var(--gold);
    // border-radius: 50%;
    overflow: visible;
    position: relative;
    font-weight: bold;
    font-size: 12px;
    padding: 2px 16px;
    align-items: center;
}

.icon-button::before,
.icon-button::after {
    content: "";
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    border-radius: 50%;
    left: 0;
    float: left;
    top: 0;
    position: absolute;
    opacity: 0;
    transition: all 0.3s ease-out 0s;
}

.icon-button:hover {
    cursor: pointer;
}

.icon-button:hover::before {
    opacity: 1;
    left: -5px;
    top: -7px;
}
.icon-button:hover::after {
    opacity: 1;
    left: 5px;
    bottom: 3px;
}
