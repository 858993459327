.Banner {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--bg-banner);
    overflow: hidden;

    &__overlay {
        opacity: 0.5;
        width: 100%;
        height: 100%;
    }

    &__bg-img {
        z-index: 0;
        // mix-blend-mode: exclusion;
        mix-blend-mode: var(--banner-blend-mode);
        position: relative;
        margin-bottom: -10px;
        max-height: 100%;

        @media screen and (min-width: 768px) {
            position: absolute;
            bottom: 0;
            right: -100px;
            height: auto;
        }
    }

    &__background-elements {
        position: relative;
        height: auto;

        @media screen and (min-width: 768px) {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }

    &__content-box {
        position: relative;
        z-index: 3;
        overflow: visible;
        margin: 0;
        padding: 0;

        h1, p {
            color: var(--font-color-headings);
        }

        & > div {
            // background-color: var(--grey-500);
            padding: 20px;
            margin: 0;
        }

        @media screen and (min-width: 768px) {
            padding-top: 215px;
            margin: 0 auto;

            & > div {
                margin: 0;
                // background-color: $grey-500;
                padding: 64px 64px 64px 20px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    width: 100vw;
                    right: 100%;
                    top: 0;
                    height: 100%;
                    // background-color: var(--grey-500);
                }
            }
        }
    }
}
