h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-headings);
    color: var(--font-color-headings);
}

h1 {
    font-size: 60px;
    line-height: 90%;
    margin-top: 8px;
}

h2 {
    font-size: 40px;
    line-height: 90%;
    margin-top: 8px;
}

h3 {
    font-size: 32px;
    line-height: 90%;
    margin-top: 8px;
}

p {
    color: var(--font-color-paragraph);

    &.lead {
        font-size: 22px;
    }
}