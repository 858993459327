@keyframes morph {
    0% {
        border-radius:60% 40% 30% 70%/60% 30% 70% 40%
    }
    50% {
        border-radius:30% 60% 70% 40%/50% 60% 30% 60%
    }
    100% {
        border-radius:60% 40% 30% 70%/60% 30% 70% 40%
    }
}

.Blobby {
    animation: morph 8s ease-in-out infinite 1s;
    background-blend-mode: multiply;
    -webkit-box-shadow: inset 0 0 0 9px rgb(214, 177, 97);
    -moz-box-shadow: inset 0 0 0 9px rgb(214, 177, 97);
    box-shadow: inset 0 0 0 9px rgb(214, 177, 97);
    background-size: cover;
}