.Navbar {
    display: flex;
    align-items: center;
    gap: 24px;

    &__links {
        display: none;
        visibility: hidden;
        height: 0;
        width: 0;
        opacity: 0;
        position: relative;

        // @media screen and (min-width: 768px) {
        //     visibility: visible;
        //     height: auto;
        //     width: auto;
        //     opacity: 1;
        // }
    }

    &__item {
        text-decoration: none;
        margin-left: 24px;
        font-size: 12px;
        text-transform: uppercase;
        color: var(--grey-900);
        height: 40px;
        padding: 0;
        display: inline-flex;
        align-items: center;
    }

    &__indicator {
        height: 3px;
        border-radius: 3px;
    }

    & > .btn {
        @media screen and (min-width: 768px) {
            display: none;
        }
    }
}



$items: 4;
$transition-duration: 0.5s;
$transition-delay: 0.05s;

.mobile-nav-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: var(--grey-600);
    border-radius: 50%;
    overflow: visible;
    position: relative;
    // @media screen and (min-width: 768px) {
    //     display: none;
    // }

    /* Toggle Functionality */
    #toggle { display: none; }

    // Styles for the 'open' state, if the checkbox is checked
    #toggle:checked {
    //Any element you need to change the style if menu is open goes here, using the sibling selector (~)
        & ~ .toggle-container .button-toggle {
            // box-shadow: 0 0 0 550px rgba( 0, 0, 0, 0.85), inset 0 0 0 20px rgba( 0, 0, 0, 0.85);
            // pointer-events: none;

            &:before {
                transform: translateY(-50%) rotate(45deg) scale(1);
            }

            &:after {
                transform: translateY(-50%) rotate(-45deg) scale(1);
            }
        }

        // Open Nav
        & ~ .mobile-nav {
            margin-bottom: -50px;
            pointer-events: auto;
            transform: translate(-100px, 50px);
            background-color: var(--grey-600);
            padding: 200px;
            z-index: 3;
            border-radius: 100%;

            & > .Navbar__indicator {
                opacity: 1;
                transition: opacity 1s;
            }
            // Restoring nav items from 'lines' in the menu icon
            .mobile-nav-item {
                color: var(--gold);
                font-size: 18px;

                letter-spacing: 0;
                height: 40px;
                line-height: 40px;
                margin-top: 0;
                opacity: 1;
                transform: scaleY(1);
                transition: $transition-duration, opacity 1s;

                // Hide the lines
                &:before {
                    opacity: 0;
                }
            }

        }

    }

    /* Toggle Button */
    .toggle-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 4;
        border-radius: 100%;
    }

    .button-toggle {
        position: absolute;
        display: inline-flex;
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 100%;
        transition: $transition-duration + 0.1;

        // Shadow on Hover
        &:hover {
            // box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5), inset 0 0 0 18px rgba(223, 21, 21, 0.5);
        }

        // Making the 'X' icon using `:before` and `:after` pseudo-elements
        // Initially hidden beause of `scale(0)` transformation
        &:before, &:after {
            position: absolute;
            content: '';
            top: 50%;
            left: 25%;
            width: 50%;
            height: 2px;
            background-color: var(--gold);
            border-radius: 5px;
            transition: $transition-duration;
        }

        &:before {
            transform: translateY(-50%) rotate(45deg) scale(0);
        }

        &:after {
            transform: translateY(50%) rotate(-45deg) scale(0);
        }
    }

    /* Menu */
    .mobile-nav {
        display: inline-block;
        pointer-events: none;
        transition: $transition-duration;
        position: absolute;
        z-index: 2;

        & > .Navbar__indicator {
            opacity: 0;
            transition: opacity 0.1s;
        }
    }

    // Showing nav items as lines, making up the hamburger menu icon
    .mobile-nav-item {
        position: relative;
        display: block;
        float: left;
        clear: both;
        color: transparent;
        letter-spacing: -6.2px;
        font-size: 14px;

        height: 7px;
        line-height: 7px;
        text-transform: uppercase;
        white-space: nowrap;
        transform: scaleY(0.2);
        transition: $transition-duration, opacity 1s;
        margin-bottom: -2px;
        text-decoration: none;

        // making lines for the hamburger menu icon
        &:before {
            position: absolute;
            content: '';
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--gold);
            transform: translateY(-50%) scaleY(5);
            transition: $transition-duration;
        }

        // Adjusting width for first line
        &:nth-child(1) {
            letter-spacing: -8px;
        }

        // Adjusting width for second line
        &:nth-child(2) {
            letter-spacing: -7px;
        }

        // Adjusting the fourth element onwards
        &:nth-child(n + 4) {
            letter-spacing: -8px;
            margin-top: -7px;
            opacity: 0;
        }

        //Set delays for the nav items in close transition
        @for $i from 1 through $items {
            &:nth-child(#{$i}) {
                $delay: ($i - 1) * $transition-delay;
                transition-delay: $delay;
                &:before {
                    transition-delay: $delay;
                }
            }
        }

    }
}