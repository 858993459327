:root {
    --font-color-headings: var(--grey-600);
    --font-color-paragraph: var(--grey-600);
    --font-family-headings: 'Anton', sans-serif;
    --font-family-body: 'Space Grotesk', sans-serif;
    --bg: var(--white);
    --bg-alt: var(--grey-100);
    --bg-banner: var(--grey-400);
    --bg-footer: var(--grey-600);
    --banner-blend-mode: screen;

}

.dark-mode {
    --font-color-headings: #fff;
    --font-color-paragraph: #fff;
    --bg: var(--grey-500);
    --bg-alt: var(--grey-600);
    --bg-banner: var(--grey-600);
    --bg-footer: var(--grey-600);
    --banner-blend-mode: exclusion;
}