.Header {
    width: 100%;
    background-color: var(--bg-banner);
    height: 48px;
    z-index: 999;
    position: fixed;

    &__wrapper {
        width: 100%;
        height: 48px;
        max-width: 1140px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @media screen and (min-width: 768px) {
        padding: 0 48px;
    }
}

.Logo {
    font-family: var(--font-family-headings);
    text-decoration: none;
    font-size: 18px;
    text-transform: uppercase;
    color: var(--font-color-headings);
}
