.ReactModalPortal {
    z-index: 9999;
    position: relative;
}

ReactModal__Body--open {
    position: fixed;
}

.ReactModal {
    &__Content {
        max-width: 1000px;
        margin: 0 auto;
    }

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: var(--grey-600);
        height: 35px;
        width: 35px;
        border-radius: 100%;

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 2px;
            background-color: var(--gold);
            left: calc(50% - 10px);
        }

        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);

        }
    }
}