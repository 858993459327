@use "sass:map";
@use "sass:color";


$colors: (
  "gold": rgb(214, 177, 97),
  mid-blue: rgb(62, 73, 122),
  pale-blue: rgb(222, 240, 255),
  grey-600: rgb(35, 42, 53),
  grey-500: rgb(77, 84, 94),
  grey-400: rgb(123, 132, 147),
  grey-300: rgb(174, 179, 188),
  grey-200: rgb(208, 212, 217),
  grey-100: rgb(243, 244, 245),
  grey-50: rgb(247, 247, 248),
  "white": rgb(255, 255, 255),
  "black": rgb(0, 0, 0),
);

@each $color, $value in $colors {
  @debug $value;
  $color: $value;
}

$spacing: (
    xxs: 4px,
    xs: 8px,
    sm: 16px,
    md: 32px,
    lg: 48px,
    xl: 64px,
    xxl: 80px,
    xxxl: 128px,
);

$heading-sizes: ();

$typography: (
    font-family-headings: "'Anton', sans-serif",
    font-family-body: "'Space Grotesk', sans-serif;",
);
// $font-family-headings: 'Anton', sans-serif;
// $font-family-body: 'Space Grotesk', sans-serif;


// Generate root variables of all colours, tints, and transparency variants
// To use in document, set the value to var(<VARIABLE NAME>)
// E.g. color: var(--class-teal);
// https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
$colors-root: ();
:root {
    @each $color, $value in $colors {
        // Create root var
        --#{$color}: #{$value};
        // Also add it to a map for docs
        $colors-root: map-merge($colors, (--#{$color}: #{$value}));
    }

    @each $typeVar, $value in $typography {
        --#{$typeVar}: #{$value};
    }

    @each $spacingVar, $value in $spacing {
        --spacing-#{$spacingVar}: #{$value};
    }
}

$spacing: 0, 8, 16, 32, 48, 64, 80, 128; // Modify this to generate your spacing classes
$unit: px; // Modify this to specify your unit eg: em, pt, %.

$directions:(
  l: left,
  t: top,
  r: right,
  b: bottom,
  v: top,
  h: left
);
@mixin generateSpacing($prop, $short  ) {
   @each $space in $spacing{
    .#{$short}a-#{$space} { #{$prop}: $space + $unit}
    @each $key,$val in $directions{
      .#{$short}#{$key}-#{$space} {
        #{$prop}-#{$val}: $space + $unit;
        @if $key == v {
          #{$prop}-bottom: $space + $unit;
        }
        @if $key == h {
          #{$prop}-right: $space + $unit;
        }
      }
    }
  }
}
@include generateSpacing(padding, p);
@include generateSpacing(margin, m);


// Define colors in the $colorConfig map.
// - Currently only one level of nesting is allowed.
// - Root variables are generated automatically.
// - Shades, tints, and transparencies are generated for interface colors
// - To use a color in a component file, instead of a variable use the color function
//   E.g.
//   .bacon {
//       color: color(class-teal);
//   }
// - You can also use the root var of course E.g. color: var(--ax-color--class-teal);
// https://itnext.io/advanced-use-of-sass-maps-bd5a47ca0d1a

// Colors in the map are currently manually exported to /01-foundation/colors/colors-sass-export.json
// From src/packages/axiom-design-system/src/ run `node node-sass-export.json` to generate
// $colorConfig: (
//     "disabled-text": #A5ADBA,
//     "input-border": #A5ADBA,
//     interface: (
//         "int-indigo": #00205A,
//         "int-black": #000000,
//         "int-white": #ffffff,
//         "int-dark-grey": #2B313A,
//         "int-washed-denim": #0C3A5A,
//     ),
//     interfaceWithTints: (
//         "int-teal": #007A91,
//         "int-warm-grey": #515B6D,
//     ),
//     status: (
//         "status-red": #CF1919,
//         "status-orange": #FF7A00,
//         "status-green": #458248,
//         "status-grey": #515B6D,
//         "status-teal": #007A91,
//     ),
//     corporate: (
//         "class-teal": #007A91,
//         "class-amethyst": #706597,
//         "class-spearmint": #0DAE8E,
//         "class-deep-sea": #002949,
//         "class-indigo": #00205A,
//         "class-rose": #AF638E,
//         "class-emerald": #87C19F,
//         "class-tea": #B4D2BE,
//         "class-grape": #8A678E,
//     ),
// );

// Generate flat colors map
// @use "sass:map";
// @use "sass:color";
// $colors: ();

// @each $item, $value in $colorConfig {
//     @if type-of(map-get($colorConfig, $item)) == 'map' {
//         @each $key, $value in map-get($colorConfig, $item) {
//             $colors: map-merge($colors, ($key: $value));
//         }
//     } @else if type-of(map-get($colorConfig, $item)) == 'color' {
//         $colors: map-merge($colors, ($item: $value));
//     }
// }

// Generate shades for interface group and add to $colors map
// $steps: 75, 50, 40, 30, 20, 10, 5;

// // -- Interface colours solid tints
// @each $colorName, $value in map-get($colorConfig, 'interfaceWithTints') {
//     @each $step in $steps {
//         $tint: mix($value, white, $step * 1%);
//         $colors: map-merge($colors, (#{$colorName}-tint-#{$step}: $tint));
//     }
// }

// //  -- Interface colours solid shades
// @each $colorName, $value in map-get($colorConfig, 'interfaceWithTints') {
//     @each $step in $steps {
//         $shade: mix($value, black, $step * 1%);
//         $colors: map-merge($colors, (#{$colorName}-shade-#{$step}: $shade));
//     }
// }

// // -- Interface colours transparency and add to $colors map
// @each $colorName, $value in map-get($colorConfig, 'interfaceWithTints') {
//     @each $step in $steps {
//         $colors: map-merge($colors, (#{$colorName}-opacity-#{$step}: rgba($value, $step / 100)));
//     }
// }


// For utility classes see: src/components/01-foundation/colors/_color-utils.scss

// Useful functions
@function color($color) {
    @return map-get($colors, $color);
}

@function tint($color, $percentage) {
    @return mix($color, white, $percentage);
}

@function shade($color, $percentage) {
    @return mix($color, black, $percentage);
}

@function transparency($color, $percentage) {
    @return rgba($color, $percentage);
}

// @debug darken(#ff0000, 10%);
// @debug color.scale(#ff0000, $lightness: -20%);

@function darkenOnHover($color, $percentage: 20) {
    // TODO if hex
    // NOTE - currently this is breaking node-sass-export, workaround is comment out, run export command then uncomment
    @return color.scale(color($color), $lightness: $percentage * -1%);
}