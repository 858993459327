
.grid-item {
    background-color: var(--grey-600);
    width: 50%;
    display: block;
    position: relative;
    background-size: cover;

    @media screen and (min-width: 768px) {
        width: 33.3333%;
    }

    &::before {
        content: "";
        padding-top: 100%;
        display: block;
        transition: all 0.5s;
    }

    &__title {
        margin-bottom: 8px;
    }

    &__content{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        color: var(--white);
        padding: 32px;
        flex-direction: column;
        justify-content: flex-end;
    }

    &:hover,
    &:focus,
    &:focus-within {
        &::before {
            background-color: var(--grey-600);
            // background-color: rgba(var(--grey-600), 0.8);
        }

        .grid-item__content {
            opacity: 1;
        }
    }

}




